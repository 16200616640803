
import './App.css';
import Header from './components/Header'
import Timer from './Timer'

function App() {
  return (
    <div className="app_l theme-1">

      <Header />
      <Timer />


    </div>
  );
}

export default App;
