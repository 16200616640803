import React, { useState, useEffect } from 'react'

function Timer({ }) {

    // time value => seconds
    // 0 -> pomodoro - 25 min,  1 - break -> 5 min
    const [TimerType, setTimerType] = useState(0)
    const [time, setTime] = useState(7)
    const [TimerRunning, setTimerRunning] = useState(false)
    const [timerInterval, setTimerInterval] = useState(null)
    const [reset, setReset] = useState(false)

    const startSound = new Audio('/assets/button-press.wav');
    const tickingSound = new Audio('/assets/ticking-slow.mp3');
    const timerOffSound = new Audio('/assets/alarm-digital.mp3');

    const startTimer = () => {
        // start timer sound
        startSound.play();
        if (reset) {
            setTime(10);
            setReset(false);
        } else setTimerRunning(!TimerRunning);
    }

    function tick() {
        setTime(time => time - 1);
    }

    const stopInterval = () => {
        clearInterval(timerInterval);
        setTimerInterval(null);
    }


    const setTitle = (title) => {
        document.title = `${title} - Timer App`;
    }

    const changeTimerType = (type) => {
        if (TimerRunning) {
            let res = window.confirm('The timer is still running, are you sure you want to switch?');
            if (res) {
                stopInterval();
                setTimerRunning(false);
                setTimerType(type);
                changeTimerTime(type);
            }
        } else {
            setTimerType(type);
            changeTimerTime(type);
        }

    }

    const changeTimerTime = (type) => {
        if (type == 0) {
            setTime(1500)
        }
        if (type == 1) {
            setTime(300)
        }
    }

    useEffect(() => {
        if (TimerType == 0) {
            setTime(1500)
            document.getElementsByClassName('app_l')[0].classList.remove('theme-2');
            document.getElementsByClassName('app_l')[0].classList.remove('theme-3');
            document.getElementsByClassName('app_l')[0].classList.add('theme-1');
        }
        if (TimerType == 1) {
            setTime(300)
            document.getElementsByClassName('app_l')[0].classList.remove('theme-1');
            document.getElementsByClassName('app_l')[0].classList.remove('theme-3');
            document.getElementsByClassName('app_l')[0].classList.add('theme-2');
        }
    }, [TimerType]);

    useEffect(() => {
        setTitle(formatTime(time));
        if (time < 1) {
            stopInterval();
            setTimerRunning(false);
            timerOffSound.play();
            setReset(true);
            setTitle("Timer Off");
        }
        if (time <= 5 && TimerRunning) {
            console.info(`timer ending - ${time}`)
            tickingSound.play();
            if (time < 1) {
                tickingSound.play()
            }
        }
    }, [time]);

    useEffect(() => {
        if (TimerRunning) {
            console.log('timer running')
            setTimerInterval(setInterval(tick, 1000))
        } else {
            stopInterval();
            console.log('timer stopped')
        }
    }, [TimerRunning]);



    return (

        <div className="container text-center">
            <div className="timer-bar">
                <div className="inner"></div>
            </div>

            <div className="timer-app">
                <div className="inner-view">

                    <div className="timer-type">
                        <button className={TimerType === 0 ? `active` : ''} onClick={() => changeTimerType(0)}>Pomodoro</button>
                        <button className={TimerType === 1 ? `active` : ''} onClick={() => changeTimerType(1)}>Short Break</button>
                    </div>

                    <div className="timer">
                        {formatTime(time)}
                    </div>

                    <div className="action">
                        <button className={`pp ${TimerRunning ? 'active' : ''}`} onClick={startTimer} >{TimerRunning ? 'PAUSE' : (reset ? 'RESET' : 'START')}</button>
                        <div className="skipBtn">
                            <button className={`skip ${TimerRunning ? 'active' : ''}`}>
                                <img src="next-white3.png" />
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>


    );
}

export default Timer;

function pad2(num) {
    return num > 9 ? num : `0${num}`;
}

export function formatTime(time) {
    const minutes = pad2(Math.floor(time / 60));
    const seconds = pad2(Math.floor(time % 60));

    return `${minutes}:${seconds}`;
}
