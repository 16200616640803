import React from 'react'
import logo from './../logo.svg';
function Header({ }) {
    return (
        <>

            <div className="container">

                <div className="header">
                    <h1>
                        <img src={logo} className="App-logo" alt="logo" />
                        Timer App
                    </h1>
                    <div className="menu-main">
                        <button> <span>Settings</span></button>
                        <button> <span>Menu 2</span></button>
                    </div>
                </div>

            </div>

        </>
    );
}

export default Header;